@import "/src/theme/core";

input[type="text"],
input[type="date"],
.input-date {
  outline: none;
  min-height: 45px;
  padding: var(--input-padding);
  border-radius: var(--border-radius);
  border: 1px solid var(--ccs-color-border);
  box-shadow: none;
  height: 45px;
  font-family: var(--ccs-font-regular);
  font-size: 15px;
  color: var(--ccs-supporting-dark-grey);
  letter-spacing: 0;
  line-height: 1;
}

input {
  &[type=text] {
    &::-ms-clear {
      display: none;
    }
  }

  &[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

::-ms-clear {
  display: none;
}

.form-label {
  font-family: var(--ccs-font-regular);
  font-size: 15px;
  color: var(--ccs-color-form-prompt);
  letter-spacing: 0;
  line-height: 20px;
}

.form-input {
  font-family: var(--ccs-font-regular);
  font-size: 15px;
  color: var(--ccs-supporting-dark-grey);
  letter-spacing: 0;
  line-height: 22px;
}

@include on-ie-11 {
  ion-radio-group {
    ion-col {
      width: auto !important;
      min-width: 25%;
    }
  }
  ion-menu[menuid="sideMenu"] .toolbar-background {
    background: var(--ccs-menu-background) !important;
  }
  ion-list .item-native {
    border-radius: 0 !important;
  }
  ion-list .item-native > .item-inner {
    border: none !important;
  }
  ion-radio-group > ion-item,
  ion-radio-group > ion-item > .item-native,
  ion-radio-group > ion-item > .item-native > .item-inner,
  ion-radio-group > ion-item > .item-native > .item-inner .input-wrapper {
    display: inline-block !important;
    white-space: normal !important;
    width: auto !important;
    min-height: 48px;
    vertical-align: top;
  }
  ion-radio-group > ion-item > .item-native > ion-radio {
    display: inline-block !important;
    white-space: normal !important;
  }
}

.ui-field-contain {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;

  &.disabled {
    opacity: 1;
    pointer-events: inherit;

    > div {
      opacity: .4;
      pointer-events: none;
    }

    > fieldset span.select2 {
      opacity: .4;
      pointer-events: none;
    }
  }

  label:not(.error) {
    float: left;
    width: 20%;
    margin: 13px 10px 0 0;
    color: var(--ccs-supporting-medium-grey);

    @include media("screen", "<tablet") {
      width: 100%;
    }

    &.form-indent {
      padding-left: 20px;
    }
  }

  > label.flipswitch-label {
    width: 75%;
    @include media("screen", "<tablet") {
      width: 100%;
    }
    &.overflow-label {
      padding-right: 30px;
      height: 25px;
    }
  }

  &.overflow-button-container {
    margin: 0 !important;
  }

  &.overflow-label-container {
    margin-bottom: 0 !important;
  }

  .required {
    font-variant-position: super;
    color: var(--ccs-risk-red);
  }

  > input {
    outline: none;
    min-height: 45px;
    padding: var(--input-padding);
    border-radius: var(--border-radius);
    border: 1px solid var(--ccs-color-border);
    box-shadow: none;
    height: 45px;
    line-height: 1;
  }

  label.error {
    color: var(--ccs-risk-red);
    margin-left: calc(20% + 10px);
    display: block;
  }

  > input,
  > .ui-select {
    width: calc(80% - 10px);
  }

  > .ui-plus-button {
    width: calc(80% - 10px);

    > input, textarea {
      display: inline-block;
      margin-right: 10px;
      width: calc(80% - 10px);
    }
  }

  > div.ui-input-text {
    border-style: solid;
    border-bottom-width: 1px;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
    border-color: var(--ccs-color-border);
  }

  textarea {
    width: calc(80% - 10px) !important;
    min-height: 72px;
    background-color: var(--ccs-base-white);
    border-color: var(--ccs-color-border);
    color: var(--ion-color-light-contrast);
    padding: var(--input-padding);
    line-height: 1.4em;
    display: block;
    border-width: 1px;
    border-style: solid;
    border-radius: .3em;
  }

  fieldset {
    border: none;
    width: calc(80% - 15px);
    padding: 0;

    .timeinterval-text {
      background-color: var(--ccs-base-white);
      border: 1px solid var(--ccs-color-border);
      border-radius: 0.3em;
      outline: none;
      padding: var(--input-padding);
      box-shadow: none;
      width: calc(50% - 10px);
      margin-right: 20px;
      height: 45px;
    }

    .select2 {
      width: calc(50% - 10px) !important;
      vertical-align: top;
    }

    .select2-selection__arrow {
      height: 41px !important;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 20px;
    }

    .select2-selection__rendered {
      line-height: 40px !important;
    }

    .select2-selection {
      height: auto;
    }
  }

  &.custom-error {
    fieldset {
      padding-left: 0;
      width: 77%;
    }
  }

  &.field-disable {
    input {
      border: none;
      padding: 0;
      font-weight: bold;
      opacity: 1;
      pointer-events: none;
      cursor: default;
    }
  }

  .field-info-icon {
    width: 23px;
    height: 23px;
    align-self: flex-end;
    margin: 0 0 7px 14px;
    cursor: pointer;
  }
}

.form-remove-icon {
  background: url('/assets/icons/icon-close-grey.svg') no-repeat center;
  cursor: pointer;
  width: 23px;
  min-height: 23px;
  background-size: 23px;
  margin: 0 10px 0 15px;
}

.flipswitch-removable {
  > div {
    float: left;
  }

  .form-remove-icon {
    height: 40px;
    float: right;
  }
}

.ui-form-controls {
  .ui-btn:first-child {
    margin-left: 0;
  }

  .ui-btn:last-child {
    margin-right: 0;;
  }
}

.ui-field-contain-break {
  justify-content: space-between;
  border: 1px transparent solid;
  margin: -1px;

  > label:not(.error) {
    margin-top: 13px;
    display: block;
    font-size: 15px;
    color: var(--ccs-supporting-medium-grey);
  }

  .required {
    font-variant-position: super;
    color: var(--ccs-risk-red);
  }

  > input {
    outline: none;
    min-height: 45px;
    padding: var(--input-padding);
    border-radius: var(--border-radius);
    border: 1px solid var(--ccs-color-border);
    box-shadow: none;
    height: 45px;
  }

  label.error {
    display: block;
    color: var(--ccs-risk-red);
  }

  > input,
  > .ui-select,
  > .inline-checkboxes {
    margin-left: 20px;
    width: 100%;
  }

  textarea {
    margin-left: 20px;
    margin-right: 20px;
    height: 72px;
    background-color: var(--ccs-base-white);
    border-color: var(--ccs-color-border);
    color: var(--ion-color-light-contrast);
    padding: var(--input-padding);
    line-height: 1.4em;
    display: block;
    border-width: 1px;
    border-style: solid;
    border-radius: .3em;
  }

  fieldset {
    border: none;
    margin-left: 20px;
    margin-right: 20px;

    .timeinterval-text {
      height: 45px;
      background-color: var(--ccs-base-white);
      border: 1px solid var(--ccs-color-border);
      border-radius: 0.3em;
      outline: none;
      padding: var(--input-padding);
      box-shadow: none;
    }

    .select2-selection__arrow {
      height: 41px !important;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 20px;
    }

    .select2-selection__rendered {
      line-height: 40px !important;
    }

    .select2-selection {
      height: 42px;
    }
  }

  .ui-select {
    &.select-type2 {
      display: flex;
      display: -ms-flexbox;
      flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      align-self: flex-start;

      label.error {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        width: 100%;
        margin: 10px 0 0;
        padding: 0;
      }

      &.actionable-select {
        .select2-container {
          flex: 1;
        }
      }
    }
  }

}

.ui-block-a,
.ui-block-b,
.ui-block-c {
  flex: 1;
  margin: 0;
  padding: 0;
  border: 0;
  float: left;
  min-height: 1px;
}

.ui-grid-none,
.ui-grid-a,
.ui-grid-b,
.ui-grid-c {
  display: flex;
  @include media("screen", "<tablet") {
    flex-wrap: wrap;
  }
}

.ui-grid-none,
.ui-grid-a,
.ui-grid-b,
.ui-grid-c {
  .ui-btn {
    margin: .5em .3125em;
    font-size: 16px;
    padding: .7em 1em;
    display: block;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;

    &.button-styled {
      font-family: var(--ccs-font-bold) !important;
      padding-top: 13px !important;
      padding-bottom: 13px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
      line-height: 19px !important;
      min-height: 45px !important;
      font-size: 16px !important;
      border-radius: var(--border-radius) !important;
      border: 1px solid var(--ccs-color-border) !important;
      box-shadow: none;
      background-color: var(--ccs-base-blue) !important;
      background-image: none !important;
      color: var(--ccs-base-white) !important;
      opacity: 1;

      &.button-styled-delete {
        background-color: var(--ion-color-danger) !important;

        &:hover {
          background-color: var(--ion-color-danger-tint) !important;
        }
      }
    }

    &.bg-neutral {
      color: var(--ccs-base-black) !important;
      background-color: var(--ccs-supporting-light-grey) !important;
    }
  }
}

@include media("screen", ">tablet") {
  .br-styled {
    display: none;
  }
}

.ui-field-contain,
.ui-mobile fieldset.ui-field-contain {
  padding: 0;
  margin: 25px 0;
  border-bottom-width: 0;
}

.ajax-file-upload-container:empty {
  margin: 0;
}

:root {
  .ajax-file-upload {
    background: var(--ccs-base-blue);
    box-shadow: none !important;
    display: flex !important;
    align-items: center;
    margin: 10px;
    padding: 0 20px;
    height: 45px;
    border-radius: var(--border-radius);
    border: 1px solid var(--ccs-color-border);
  }

  .image-upload {
    width: calc(80% - 10px);

    .object-block {
      a {
        display: block;
        padding: 20px 5px;
      }
    }

    .image-block {
      display: flex;

      img {
        background: var(--ccs-supporting-light-grey);
        width: auto;
        min-width: 150px;
        min-height: 150px;
      }

      ion-button {
        margin: 0;
        margin-left: 5px;
      }
    }
  }

  .image-upload-control {
    .ajax-upload-dragdrop {
      display: flex;
      align-items: center;
      padding: 0;
      vertical-align: middle;
      width: 100% !important;
      border: 2px dashed var(--ccs-supporting-light-grey);

      div {
        margin-top: 10px;
        font-family: var(--ccs-font-bold);
      }

      span {
        color: var(--ccs-supporting-medium-grey);
      }
    }

    .ajax-file-upload-error {
      color: var(--ccs-risk-red);
      padding-top: 20px;
    }
  }
}

hr.form-divider {
  margin-top: 20px;
  height: 1px;
  background-image: linear-gradient(90deg, #E6E6E6, #E6E6E6 75%, transparent 75%, transparent 100%);
  background-size: 10px 1px;
  border: none;
}

.form-divider-title {
  margin-top: 20px;
  margin-bottom: 20px;

  &.question-type-field {
    margin: 30px 0;
  }
}

app-tabs ~ * .form-divider-title {
  margin-top: 30px;
}

.inline-checkboxes {
  width: calc(80% - 15px);
}

.checkbox-label {
  display: block;
  position: relative;
  padding-right: 25px;
  float: left;
  margin: .5em 0 .4em;
  user-select: none;

  input[type="checkbox"] {
    padding-top: 18px !important;
    padding-left: 9px !important;
    padding-right: 9px !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
    margin-top: 0 !important;
    -webkit-appearance: none;
    background-color: var(--ccs-base-white);
    border: 1px solid var(--ccs-color-border);
    box-shadow: none;
    border-radius: var(--border-radius-checkbox);
    position: relative;
    float: left;

    &:checked {
      &:after {
        content: '';
        background: url('/assets/icons/icon-checkmark.svg') no-repeat;
        position: absolute;
        width: 13px;
        height: 10px;
        margin-top: 5px;
        margin-left: 4px;
        z-index: 11;
        top: 0;
        left: 0;
      }
    }
  }
}

fieldset {
  .select2-selection--single {
    border-color: var(--ccs-color-border) !important;
  }
}

.form-panel-values {
  width: calc(80% - 10px);

  a {
    color: var(--ccs-base-blue);
    font-family: var(--ccs-font-bold)
  }

  .ui-btn {
    font-size: 16px;
    margin: .5em 0;
    padding: .7em 1em;
    display: block;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
  }

  .ui-btn-inline {
    display: inline-block;
    vertical-align: middle;
    margin-right: .625em;
  }

  .ui-btn-icon-left {
    padding-left: 2.5em;
  }

  .ui-btn-inline {
    display: inline-block;
    vertical-align: middle;
    margin-right: .625em;
  }

  .ui-btn {
    background-color: var(--ccs-supporting-light-grey3);
    border: 1px solid var(--ccs-color-border);
    color: var(--ion-color-dark-tint);
  }

  .ui-icon-delete {
    ion-icon {
      position: absolute;
      left: 9px;
      top: 10px;
      font-size: 22px;
    }
  }
}

.ui-content {
  border-width: 0;
  overflow: visible;
  overflow-x: hidden;
  padding: 1em;
}

.delete-button {
  &:hover {
    color: var(--ccs-base-blue);
  }
}

ion-radio {
  --height: 25px;
  --width: 25px;
  --color-checked: var(--ion-color-secondary);
  --border: 1px solid grey;
  --border-radius: 10px !important;
  margin-right: 10px !important;
}

ion-checkbox {
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-end: 10px;
  margin-right: 10px !important;
  --checkbox-background-checked: var(--ccs-base-white);
  --border-color-checked: var(--ion-color-secondary);
  --border-color: var(--ccs-color-border);
  --border-width: 1px;
  --checkmark-color: transparant;
  --border-radius: var(--border-radius-checkbox);
  border-radius: var(--border-radius-checkbox);
  min-width: 20px;
  height: 20px;
  min-height: 20px;

  &::part(container) {
    width: 20px;
    height: 20px;
  }

  &.checkbox-checked {
    --border-width: 2px;

    &:before {
      content: '';
      background: url('/assets/icons/icon-checkmark.svg') no-repeat;
      position: absolute;
      width: 13px;
      height: 10px;
      margin: 5px 0 0 3px;
      z-index: 11;
    }
  }
}

@include on-ie-11 {
  ion-radio {
    input {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    .radio-inner {
      background: var(--ion-color-secondary);
    }

    &.radio-checked {
      .radio-icon {
        border-color: var(--ion-color-secondary) !important;
      }
    }
  }
  ion-checkbox {
    &.checkbox-checked {
      .checkbox-icon {
        border-color: var(--ion-color-secondary) !important;
        background: white !important;
      }
    }

    .checkbox-icon {
      path {
        opacity: 0;
      }
    }
  }
  .item-inner {
    padding: 0 !important;

    ion-label {
      padding-left: 10px !important;
    }
  }
  ion-col {
    flex: 1 !important;
  }
}

ion-label {
  border: none;
}

ion-item {
  --background-hover: transparent;
  --ripple-color: transparent;

  &.form-item {
    --padding-start: 0;
  }
}

ion-toggle {
  --border-radius: 6px !important;
}

.full-width .ui-select {
  width: 98% !important;
}

.required-checkbox-handler {
  width: 0;
  height: 0;
  padding: 0;
  border: 0;
  opacity: 0;
}

.hidden-form-input {
  width: 0;
  height: 0;
  padding: 0 !important;
  opacity: 0;
  position: absolute;
}

.text-translations-block {
  background: var(--ccs-supporting-light-grey3);
  border: 1px solid var(--ccs-supporting-border-grey);

  &.ui-field-contain-break {
    br {
      display: none;
    }
  }

  .text-translations-content {
    padding: 20px 20px 20px 30px;
  }

  .collapsible-block {
    width: 100%;

    .collapsible-item {
      font-family: var(--ccs-font-semibold);
      font-size: 15px;
      color: var(--ccs-supporting-dark-grey);
      line-height: 20px;
      cursor: pointer;
      user-select: none;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: 37px;
        top: 23px;
        @include arrow;
        @include down-arrow-direction;
        border-color: var(--ccs-supporting-medium-grey);
      }

      &.active-block {
        background: var(--ccs-base-dark-blue);
        color: var(--ccs-base-white);

        &:after {
          @include up-arrow-direction;
          border-color: var(--ccs-base-white);
          top: 28px;
        }
      }
    }

    .ui-field-contain {
      padding: 10px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .headline-small,
  .body-copy-bold,
  .ui-field-contain {
    margin: 0;
  }

  .ui-field-contain {
    .form-indent {
      padding-left: 0;
    }
  }
}

.changes-panel {
  padding-bottom: 90px;

  &.hidden-panel {
    padding-bottom: 0;

    .ui-form-controls {
      display: none;
    }

    &.active-panel {
      padding-bottom: 95px !important;

      .ui-form-controls {
        display: flex;
        visibility: visible;
      }
    }
  }

  .ui-form-controls {
    padding: 18px 40px !important;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 15px;
    background: var(--ccs-supporting-light-grey);
    display: flex;
    align-items: center;
    z-index: 111;
    @include media("screen",
      "<tablet") {
      flex-wrap: wrap;
    }

    .cancel-button {
      color: var(--ccs-supporting-dark-grey) !important;
    }

    .changes-count-title {
      color: var(--ccs-supporting-dark-grey);
      font-size: 15px;
      letter-spacing: 0;
      line-height: 18px;
      padding-right: 5px;
      margin: 5px 0;

      .changes-count-value {
        font-family: var(--ccs-font-semibold);
      }
    }

    .button-styled {
      margin: 0 5px;

      &.disabled {
        opacity: .4 !important;
      }
    }
  }
}

// Fix for latest Chrome Version 83.0.4103.97 black border on focus
*:focus {
  outline: none;
}

// Checkbox with label crossbrowser
.checkbox-label-crossbrowser {
  display: inline-block;
  position: relative;
  user-select: none;

  input[type="checkbox"] {
    position: absolute;
    width: 20px;
    height: 20px;
    appearance: none;
    outline: none;
    z-index: 1;

    &::-ms-check {
      opacity: 0;
    }

    &:focus {
      outline: none;
    }

    + label {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
    }

    + label:before {
      content: "";
      display: block;
      width: 19px;
      height: 19px;
      background: var(--ccs-base-white);
      border: 1px solid var(--ccs-color-border);
      border-radius: var(--border-radius-checkbox);
      margin-top: 1px;
    }

    &:checked + label:before {
      border-color: var(--ccs-base-blue);
      box-shadow: inset 0 0 0 1px var(--ccs-base-blue);
    }

    &:checked + label:after {
      content: "" !important;
      background: url("/assets/icons/icon-checkmark.svg") top left no-repeat;
      position: absolute !important;
      width: 13px;
      height: 10px;
      top: 1px;
      bottom: 0;
      left: 0.4px;
      right: 0;
      margin: auto;
    }
  }
}

// Custom Inputs
.custom-inputs {
  #siteMessageAddForm {
    .ui-field-contain {
      margin: 25px 0;
    }
  }

  @include media("screen",
    "<phone") {
    .ui-grid-a,
    .ui-grid-b {
      a.ui-btn.button-styled {
        padding: 0 10px !important;
        font-size: 14px !important;
        margin: 0 20px 0 0;
      }
    }
  }

  #siteHeaderMessageEditForm,
  #subscriberPreferenceForm,
  #siteMessageAddForm,
  #siteMessageEditForm {
    label {
      width: calc(62px + 95px);

      &.error {
        padding-left: calc(62px + 95px);
        width: 100%;
        @include media("screen",
          "<tablet") {
          padding-left: 0 !important;
        }
      }
    }

    .ui-select.select-type2 {
      label.error {
        padding: 0;
      }
    }

    .report-field {
      & > div {
        width: 343px;
        @include media("screen",
          "<tablet") {
          width: 300px;
        }
      }
    }

    input:not(.select2-search__field),
    textarea,
    .ui-select {
      width: 343px;
      @include media("screen",
        "<tablet") {
        width: 300px;
      }
    }
  }

  label.unique-error {
    font-family: inherit !important;
  }

  label.error,
  label.unique-error {
    order: 3;
    width: 100%;
    margin: 10px 0 0;
    padding-left: 22%;
    @include media("screen",
      "<tablet") {
      padding-left: 0 !important;
    }
  }

  .ui-field-contain-break, .ui-field-contain {
    margin: 25px 0;
  }

  .ui-field-contain {
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;

    #ruleAddinterval_beacon_label,
    #ruleAddinterval_observation_label,
    #ruleAddinterval_proximity_label,
    #ruleAddfrequency_label,
    #ruleEditinterval_beacon_label,
    #ruleEditinterval_observation_label,
    #ruleEditinterval_proximity_label,
    #ruleEditfrequency_label,
    #cultureAccountabilityEdittime_open_label,
    #cultureAccountabilityEdittime_close_label,
    #cultureAccountabilityEdittime_unassigned_label {
      & + fieldset {
        width: calc(80% - 10px);
        padding: 0;
        @include media("screen",
          "<tablet") {
          width: 100%;
        }

        & > .select2-container {
          margin-left: 10px;

          &--open {
            .select2-selection__arrow b {
              border: none;
              -webkit-transform: translateY(0%) rotate(180deg);
              transform: translateY(0%) rotate(180deg);
            }
          }

          .select2-selection__arrow {
            width: 10px;
            right: 20px;

            b {
              border-top: 5px solid var(--ccs-supporting-medium-grey2);
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
            }
          }

          @include media("screen",
            "<phone") {
            margin-left: 0;
            margin-top: 10px;
          }
        }

        & > input,
        & > .select2-container {
          width: 45% !important;
          @include media("screen",
            "<phone") {
            width: 100% !important;
          }
        }
      }
    }

    fieldset {
      padding: 0 10px;
      margin: 0;

      ion-col {
        padding: 0;
      }

      .item-native {
        min-height: 1px;

        .item-inner {
          min-height: 1px;

          .input-wrapper {
            min-height: 1px;
          }
        }
      }

      ion-item {
        min-height: 1px;
        height: auto;

        ion-label {
          padding: 0;
          min-height: 1px;
          height: auto;
          margin: 0;
          font-size: 15px;
          font-family: var(--ion-font-family) !important;
          color: var(--ccs-supporting-dark-grey);
          @include media("screen",
            "<tablet") {
            white-space: normal;
            word-wrap: break-word;
          }
        }

        ion-checkbox {
          margin-left: 0 !important;
          margin-right: 9px !important;
        }
      }
    }

    @include media("screen",
      "<phone") {
      margin: 0 0 15px;
      flex-wrap: wrap;
      -ms-flex-wrap: wrap;
    }

    textarea {
      &:not(.auto-grow) {
        resize: none;
        min-height: 95px;
      }

      &.auto-grow {
        min-height: unset;
        max-height: 300px;
        height: 47px;
      }
    }
  }

  input:not(.select2-search__field),
  textarea,
  div.ui-input-text {
    border-radius: var(--border-radius);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px var(--ccs-color-border);
    font-size: 15px;
    background-color: var(--ccs-base-white);
    padding: var(--input-padding);
  }

  textarea.textarea-break {
    width: 100%;
    height: 120px;
    margin: 0;
    color: var(--ccs-supporting-dark-grey);
    font-size: 15px;
    letter-spacing: 0;
    line-height: 20px;
  }

  label {
    line-height: 1.33;
    color: var(--ccs-color-form-prompt);
    font-size: 15px;
    margin: 11px 0 0;
    @include media("screen",
      "<tablet") {
      margin: 0 0 5px;
      width: 100%;
    }
  }

  .report-field {
    & > div {
      @include media("screen",
        "<tablet") {
        width: 100%;
      }

      ion-toggle {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  input:not(.select2-search__field),
  textarea,
  .ui-select {
    @include media("screen",
      "<tablet") {
      width: 100% !important;
    }
  }

  .form-date-flipswitch, .form-date-range-picker, .ui-select.select-type2 {
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    align-self: flex-start;

    label.error {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
      width: 100%;
      margin: 10px 0 0;
      padding: 0;
    }

    &.actionable-select {
      .select2-container {
        flex: 1;
      }
    }

    .form-date-range-picker {
      select2 {
        width: 100%;
      }
    }
  }

  .ui-grid-a,
  .ui-grid-b {
    .ui-btn {
      &.button-styled {
        &.inactive {
          pointer-events: none;
          opacity: .2 !important;
        }
      }
    }
  }
}

.search_all {
  @include media("screen",
    ">tablet") {
    float: right;
  }

  &.group-search {
    @include media("screen",
      ">tablet") {
      margin-top: -120px;
    }
  }

  &::before {
    left: 15px !important;
  }
}

.ui-field-contain-break label.error {
  width: 100%;
}

.custom-element-content {
  width: 100%;
}

.form-date-range-picker {
  .select2-container {
    width: 100% !important;
  }
}

.custom-element-container {
  width: 100%;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
}

.my-folder-tag {
  width: calc(80% - 10px);
  font-family: var(--ccs-font-semibold);
  color: var(--ccs-table-text);
  font-size: 15px;
}

.ui-field-contain.custom-field-button-folder {
  justify-content: normal !important;
}

div#locationInformationfolderID_container {
  justify-content: normal !important;
}

button#locationInformationfolderID {
  margin-top: 8px;
}

.selected-location {
  margin-right: 5px;
  border: 1px solid var(--ccs-color-field-border);
  border-radius: 4px;
  background-color: var(--ccs-color-field);
  padding: 5px;
  color: var(--ccs-color-field-text);
  margin-top: 7px;
  font-size: 16px;
  font-family: var(--ccs-font-semibold);
  width: max-content;
  display: inline-flex;

  &.folder {
    @include folder-icon;
  }
}

.node-selection-display {
  width: calc(80% - 10px);
  display: flex;
  flex-wrap: wrap;
}

.modal-user-hierarchy-location .treeview-container {
  max-height: none !important;
}

a.disabledElement {
  pointer-events: none;
  opacity: 0.4 !important;
}

.forgot-button-style {
  --padding-end: 22.5px;
  --padding-start: 22.5px;

  &.button-disabled {
    opacity: .2 !important;
  }
}

form {
  ion-radio-group {
    display: flex;
    flex-wrap: wrap;

    ion-radio {
      input {
        width: 0;
      }
    }
  }
}

ion-icon.delete-row {
  padding-top: 11px;
  vertical-align: top;
  font-size: 24px;
  color: var(--ccs-supporting-medium-grey) !important;

  &:hover {
    color: var(--ccs-supporting-dark-grey) !important;
  }
}

.button-styled {
  &.top-aligned {
    vertical-align: top;
  }
}

ion-textarea {
  border: 1px solid var(--ccs-color-border);
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .15);
}
