@import "/src/theme/core";

.folder-name {
  color: var(--ccs-color-field-text);
  font-family: var(--ccs-font-semibold);
  font-size: 16px;
  line-height: 23px;
  padding: 6px 10px;
  border: 1px solid var(--ccs-color-field-border);
  border-radius: 4px;
  background-color: var(--ccs-color-field);
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  @include folder-icon;
}
